import React from 'react';
import './Header.css';
//import { DarkTheme } from '../DarkTheme/DarkTheme'; // Import the new component
import { Information } from '../Information/Information'; // Import the new component


interface HeaderProps {
  theme: string;
  toggleTheme: () => void;
}

/*
export const Header: React.FC<HeaderProps> = ({ theme, toggleTheme }) => {
    return (
        <header className="App-header">
        <div className="logo-container">
            <img src="/duck-logo.png" alt="Career Duck Logo" className="App-logo" />
            <h1>Career Duck</h1>
        </div>
        <DarkTheme toggleTheme={toggleTheme} theme={theme} />
        </header>
    );
};
*/

export const Header: React.FC<HeaderProps> = ({ theme, toggleTheme }) => {
    return (
        <header className="App-header">
        <div className="logo-container">
            <img src="/duck-logo.png" alt="Career Duck Logo" className="App-logo" />
            <h1>Career Duck</h1>
        </div>
        <div className="theme-info-container"> {/* New container for both components */}
            {/*    <DarkTheme toggleTheme={toggleTheme} theme={theme} />*/}
            <Information /> 
        </div>
        </header>
    );
};