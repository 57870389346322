// Declare the gtag function type for TypeScript
interface Window {
    gtag: (
      command: string,
      action: string,
      params: {
        event_category?: string;
        event_label?: string;
        link_url?: string;
        [key: string]: any;
      }
    ) => void;
  }
  
  // Define parameter types for better type safety
  interface GAEventParams {
    event_category?: string;
    event_label?: string;
    link_url?: string;
    [key: string]: any;
  }
  
  // Event name constants
  export const GA_EVENTS = {
    PAGE_VIEW: 'page_view',
    BUTTON_CLICK: 'button_click',
    FORM_SUBMIT: 'form_submission',
    EXTERNAL_LINK: 'external_link_click',
    ERROR: 'error',
    FEATURE_USAGE: 'feature_usage',
  } as const;
  
  // Event category constants
  export const GA_CATEGORIES = {
    NAVIGATION: 'navigation',
    ENGAGEMENT: 'engagement',
    FORM: 'form',
    OUTBOUND: 'outbound',
    ERROR: 'error',
    SOCIAL: 'social'
  } as const;
  
  /**
   * Tracks an event in Google Analytics
   */
  export const trackGAEvent = (
    action: string,
    category?: string,
    label?: string,
    additionalParams: Record<string, any> = {}
  ) => {
    if (typeof window !== 'undefined' && 'gtag' in window) {
      const params: GAEventParams = {
        ...additionalParams
      };
  
      if (category) {
        params.event_category = category;
      }
  
      if (label) {
        params.event_label = label;
      }
  
      // @ts-ignore - TypeScript doesn't know about gtag
      window.gtag('event', action, params);
    }
  };
  
  /**
   * Common event tracking functions for reuse
   */
  export const googleAnalytics = {
    // Basic tracking functions
    trackPageView: (pagePath: string) => {
      trackGAEvent(GA_EVENTS.PAGE_VIEW, GA_CATEGORIES.NAVIGATION, pagePath);
    },
  
    trackButtonClick: (buttonName: string) => {
      trackGAEvent(GA_EVENTS.BUTTON_CLICK, GA_CATEGORIES.ENGAGEMENT, buttonName);
    },
  
    trackFormSubmission: (formName: string, success: boolean) => {
      trackGAEvent(GA_EVENTS.FORM_SUBMIT, GA_CATEGORIES.FORM, formName, { success });
    },
  
    trackError: (errorType: string, errorMessage: string) => {
      trackGAEvent(GA_EVENTS.ERROR, errorType, errorMessage);
    },
  };