// FeedbackSection/ErrorMessage.tsx
import React from 'react';
import './FeedbackSection.css';

interface ErrorMessageProps {
  error: string;
  onSwitchToManual: () => void;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, onSwitchToManual }) => {
  const renderErrorContent = () => {
    if (error.includes('The content of the job posting has insufficient information.')) {
      return (
        <>
          Sorry... We couldn't extract the job details from this URL. Please{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
        </>
      );
    } else if (error.includes("This URL does not appear to be a job posting")) {
      return (
        <>
          Psst... This URL does not appear to be a job posting. Can you double check the URL, or{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          ?
        </>
      );
    } else if (error.includes("We are not able to process job posts from certain sites")) {
      return (
        <>
          Sorry... We couldn't extract job details from certain sites. Could you find the original post, or{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          ?
        </>
      );
    } else if (error.includes("Unavailable For Legal Reasons")) {
      return (
        <>
          Sorry... We couldn't extract the job details from this URL. Please{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
        </>
      );
    }
    return <>Psst... {error}😵‍💫</>;
  };

  return (
    <div className="error-message" data-testid='error-msg'>
      {renderErrorContent()}
    </div>
  );
};