import React, { useState, useEffect } from 'react';
import { Analytics } from "@vercel/analytics/react";
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from './hooks/useTheme';
import { LoadingOverlay } from './components/LoadingOverlay';
import { Header } from './components/Header';
import { TabInput } from './components/TabInput';
import { FeedbackMessage, ErrorMessage } from './components/FeedbackSection';
import { JobResults } from './components/JobResults';
import { JobData, SubmissionData, ApiError } from './types/types';
import { trackGAEvent, googleAnalytics } from './utils/analytics';
import { ValidateInput, validateEmail } from './utils/validation';
import { submitJobData } from './services/jobService';
import { sendJobEmail } from './services/emailService';
import './App.css';
import './styles/theme.css';
import 'react-toastify/dist/ReactToastify.css';  // Add this import

const API_URL = process.env.REACT_APP_API_URL || 'https://decisive-fold-436618-h2.uk.r.appspot.com';

function App() {
  const { theme, toggleTheme } = useTheme();

  const [url, setUrl] = useState('');
  const [jobData, setJobData] = useState<JobData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [email, setEmail] = useState('');
  const [submittedUrl, setSubmittedUrl] = useState('');

  useEffect(() => {
    toast.success('Welcome to Career Duck! 👋', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }, []); // Empty dependency array means this runs once when component mounts


  const handleSubmit = async (submissionData: SubmissionData) => {
    trackGAEvent('gain_insights_clicked', 'engagement', submissionData.url);
  
    setIsLoading(true);
    setError('');
    setJobData(null);
    setShowFeedback(false);
    setSubmittedUrl(submissionData.url);

    const validationResult = ValidateInput(submissionData);
    if (!validationResult.isValid) {
      setError(validationResult.errorMsg);
      toast.error('Failed to retrieve outputs 😕');
      trackGAEvent('input_validation_error', 'error', validationResult.errorMsg);
      setIsLoading(false);
      return;
    }
  
    try {
      await submitJobData(
        submissionData,
        API_URL,
        (data) => {
          setJobData(data);
          setShowFeedback(true);
          toast.success('Outputs retrieved successfully! 🎉');
          trackGAEvent('job_data_retrieved', 'success', submissionData.url);
        },
        (errorMsg) => {
          setError(errorMsg);
          toast.error('Failed to retrieve outputs 😕');
          trackGAEvent('job_data_error', 'error', errorMsg);
        }
      );
    } catch (error) {
      // Error already handled in service
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendEmail = async () => {
    const emailValidation = validateEmail(email);
    if (!emailValidation.isValid) {
      toast.error(emailValidation.errorMsg);
      return;
    }

    if (!jobData || !jobData.parsed_bullets) {
      toast.error('Please ensure job data is loaded first.');
      return;
    }

    try {
      await sendJobEmail(email, jobData, submittedUrl, API_URL);
      trackGAEvent('email_sent', 'engagement', email);
      toast.success('💡 Bullet points have been sent!');
    } catch (error) {
      console.error('Email sending error:', error);
      toast.error('Failed to send email. Please try again.');
    }
  };

  const handleSwitchToManual = () => {
    trackGAEvent('switch_to_manual_from_error', 'navigation', 'from_error');
    const tabInputElement = document.querySelector('.tab-button:nth-child(2)') as HTMLElement;
    if (tabInputElement) {
      tabInputElement.click();
    }
  };
    
  return (
    <>
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={theme === 'dark' ? 'dark' : 'light'}
      />
      {isLoading && <LoadingOverlay visible={isLoading} />}
      <Header theme={theme} toggleTheme={toggleTheme} />
      <main>
        <h1>Get Job Summary and Resume Bullet Points</h1>
        <h1 className="subtitle">The Easy Way</h1>
        <TabInput 
          onSubmit={handleSubmit}
          isLoading={isLoading}
          url={url}
          setUrl={setUrl}
        />

        {showFeedback && (
          <FeedbackMessage
            onSwitchToManual={handleSwitchToManual}
            email={email}
            setEmail={setEmail}
            onSendEmail={handleSendEmail}
          />
        )}

        {error && (
          <ErrorMessage 
            error={error}
            onSwitchToManual={handleSwitchToManual}
          />
        )}

        <JobResults jobData={jobData} />
      </main>
    </div>
    <Analytics />
    </>
  );
}

export default App;