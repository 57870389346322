// components/JobResults/JobResults.tsx
import React from 'react';
import { JobSummary } from './JobSummary';
import { ResumeBullets } from './ResumeBullets';
import { JobData } from '../../types/types';
import './JobResults.css';

interface JobResultsProps {
  jobData: JobData | null;
}

export const JobResults: React.FC<JobResultsProps> = ({ jobData }) => {
  return (
    <div className="results-container">
      <JobSummary jobData={jobData} />
      <ResumeBullets jobData={jobData} />
    </div>
  );
};