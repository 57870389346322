import React from 'react';
import './LoadingOverlay.css';

export const LoadingOverlay: React.FC<{ visible: boolean }> = ({ visible }) => (
  visible ? (
    <div className="loading-overlay">
    <div className="loading-content">
      <div className="water-container">
        <img src="/duck-logo.png" alt="Rubber Duck" className="duck-logo" />
        </div>
        <p className="white-message">Use "Send💡Bullet Point Ideas" to share the outputs with yourself or others!</p>
        </div>
  </div>
  ) : null
);