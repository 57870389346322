import { initializeApp, getApps, FirebaseError, FirebaseApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp, Firestore } from 'firebase/firestore';

// Move interfaces to types/types.ts if not already there
interface SearchRecordInput {
  url: string;
  input_method: 'url' | 'manual';
  job_title: string | null;
  company: string | null;
  response: {
    job_content?: any;
    parsed_summary?: any;
    parsed_bullets?: any;
  } | null;
  error?: string | null;
}

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyCcJx6nqFfYEqNiIHMmYOzU7xPHuKMUnY0",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "decisive-fold-436618-h2.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "decisive-fold-436618-h2",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "decisive-fold-436618-h2.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "32723243782",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:32723243782:web:39bec5a7d243f6798d4487"
};

// Initialize Firebase
let app: FirebaseApp;
let db: Firestore;

try {
  if (!getApps().length) {
    app = initializeApp(firebaseConfig);
  } else {
    app = getApps()[0];
  }
  db = getFirestore(app);
} catch (error) {
  console.error("Error initializing Firebase:", error);
  throw new Error("Failed to initialize Firebase. Please check your configuration.");
}

// Validate database connection
export const validateDbConnection = async (): Promise<boolean> => {
  try {
    const testCollection = collection(db, 'Search_Record');
    if (!testCollection) {
      throw new Error('Failed to access Firestore collection');
    }
    return true;
  } catch (error) {
    console.error("Database connection error:", error);
    return false;
  }
};

// Record search function
export async function recordSearch(searchData: SearchRecordInput): Promise<string | { success: boolean; error?: string; timestamp: string }> {
  try {
    const isConnected = await validateDbConnection();
    if (!isConnected) {
      throw new Error('Database connection failed');
    }

    if (!searchData.url) {
      throw new Error('URL is required');
    }
    const userAgent = typeof navigator !== 'undefined' ? navigator.userAgent : 'unknown';
    const platform = typeof navigator !== 'undefined' ? navigator.platform : 'unknown';
    const hostname = typeof window !== 'undefined' ? window.location.hostname : 'unknown';

    const searchRecord = {
      ...searchData,
      timestamp: serverTimestamp(),
      metadata: {
        userAgent,
        platform,
        environment: process.env.NODE_ENV || 'development',
        hostname,
      }
    };

    const docRef = await addDoc(collection(db, 'Search_Record'), searchRecord);
    console.log("Search record saved with ID:", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error saving search record:", error);
    return {
      success: false,
      error: error instanceof Error ? error.message : 'An unknown error occurred',
      timestamp: new Date().toISOString()
    };
  }
}

// Export the db instance
export { db };