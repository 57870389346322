import React, { useState } from 'react';
import { X } from 'lucide-react';
import { SubmissionData } from '../../utils/validation';
import { trackGAEvent } from '../../utils/analytics';
import './TabInput.css';

interface TabInputProps {
  onSubmit: (data: SubmissionData) => Promise<void>;
  isLoading: boolean;
  url: string;
  setUrl: (url: string) => void;
  error?: string;
  switchToManual?: () => void;
}

export const TabInput = ({ onSubmit, isLoading, url, setUrl, switchToManual }: TabInputProps) => {
    const [inputMethod, setInputMethod] = useState<'url' | 'manual'>('url');
    const [content, setContent] = useState('');
    
    // Method to switch to manual mode
    const handleSwitchToManual = () => {
        trackGAEvent('switch_to_manual_from_tab', 'navigation', 'tab_click');
        setInputMethod('manual');
        if (switchToManual) {
            switchToManual();
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      
      const submissionData: SubmissionData = {
        url,
        content: inputMethod === 'manual' ? content : null,
        method: inputMethod
      };
  
      await onSubmit(submissionData);
    };

  return (
    <div className="input-wrapper">
      {/* Input Method Tabs */}
      <div className="tab-container">
        <button
          type="button"
          onClick={() => setInputMethod('url')}
          className={`tab-button ${inputMethod === 'url' ? 'active' : ''}`}
        >
          URL Only 🌐
        </button>
        <button
          type="button"
          onClick={handleSwitchToManual}
          className={`tab-button ${inputMethod === 'manual' ? 'active' : ''}`}
          >
          URL + Description 📝
        </button>
      </div>

      {/* Input Form */}
      <form onSubmit={handleSubmit} className="input-form">
        <div className="input-container">
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Paste a job post URL here..."
            className="url-input"
            data-testid="joblink"
            required
          />
          {url && (
            <button
              type="button"
              onClick={() => setUrl('')}
              className="url-clear-button"
              data-testid="clearBtn"
            >
              <X width={16} height={16} />
            </button>
          )}
        </div>

        {inputMethod === 'manual' && (
          <div className="textarea-container">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Paste a full job description that includes: company name, job title, location, role responsibilities, required and preferred qualifications, and benefits, for best results..."
              className="textarea-input"
              data-testid="jobcontent"
              required
            />
            {content && (
              <button
                type="button"
                onClick={() => setContent('')}
                className="textarea-clear-button"
              >
                <X width={16} height={16} />
              </button>
            )}
          </div>
        )}

        <button
          type="submit"
          disabled={isLoading}
          className="submit-button"
          data-testid="submitBtn"
        >
          {isLoading ? 'Loading...' : 'Get Insights'}
        </button>
      </form>
    </div>
  );
};