import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { trackGAEvent } from '../../utils/analytics';
import './Information.css';

export const Information = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        try {
          // Try to access localStorage - this might fail in incognito
          const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
          
          if (!hasVisitedBefore) {
            setIsOpen(true);
            
            trackGAEvent(
              'about_modal_auto_open',
              'engagement',
              'First Time Visitor Auto Modal Open'
            );

            // Set flag in localStorage to identify returning visitors
            localStorage.setItem('hasVisitedBefore', 'true');
          }
        } catch (error) {
          // localStorage not available - possibly in incognito
          console.log('Storage not available, modal behavior may be affected');
        }
      }, []);
  
    const handleAboutClick = () => {
        trackGAEvent('about_button_click', 'engagement', 'About Modal Open');
        setIsOpen(true);
      };

    const handleLinkedInClick = (clickSource: 'image' | 'icon') => {
        trackGAEvent(
            'linkedin_profile_click', 
            'social', 
            `LinkedIn Click - ${clickSource}`, 
            { link_url: 'https://www.linkedin.com/in/jenchinwang/' }
        );
    };

    const handleClose = (dontShowAgain: boolean) => {
        if (dontShowAgain) {
            try {
            localStorage.setItem('hasVisitedBefore', 'true');
            } catch (error) {
            console.log('Unable to save preference');
            }
        }
        setIsOpen(false);
    };

    return (
        <div>
            <button 
            onClick={handleAboutClick}
            className="about-button"
            aria-label="About"
            >
        About
      </button>

      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="header">
              <h2 className="title">About Career Duck</h2>
              <button 
                onClick={() => setIsOpen(false)}
                className="close-button"
              >
                <X width={20} height={20} />
              </button>
            </div>

            <div className="content">
              <section className="section">
                <h3 className="section-title">Our Vision</h3>
                <p className="text">
                At Career Duck, we envision a future where AI and human work in harmony to unlock career potential. Our AI amplifies human judgement by identifying relevant experience patterns from millions of instances, empowering individuals to personalize these insights through their unique journey.
                </p>
                <p className="text">
                We believe the most compelling career stories emerge when AI's pattern recognition capabilities merge with authentic personal narratives - creating resumes that are both data-informed and genuinely human.
                </p>
              </section>

              <section className="section">
                <h3 className="section-title">How It Works</h3>
                <div>
                  <div className="step-container">
                    <div className="step-number">1</div>
                    <div className="step-content">
                      <p className="step-title">Find a Job Post URL</p>
                      <p className="text">Locate the job posting you're interested in and copy its URL</p>
                    </div>
                  </div>
                  <div className="step-container">
                    <div className="step-number">2</div>
                    <div className="step-content">
                      <p className="step-title">Paste URL into Career Duck</p>
                      <p className="text">Our AI analyzes the job posting instantly</p>
                    </div>
                  </div>
                  <div className="step-container">
                    <div className="step-number">3</div>
                    <div className="step-content">
                      <p className="step-title">Get Tailored Outputs</p>
                      <p className="text">Receive summary and bullet point recommendation</p>
                    </div>
                  </div>
                  <div className="step-container">
                    <div className="step-number">4</div>
                    <div className="step-content">
                      <p className="step-title">Personalize for Resume </p>
                      <p className="text">Review outputs, personalize to make it your own</p>
                    </div>
                  </div>
                </div>
              </section>    

              <section className="section">
              <h3 className="section-title">Team</h3>
                <div className="team-member">
                  <img src="/career-duck-founder.jpg" alt="James Wang" className="avatar" />
                  <div>
                    <h4 className="step-title">James Wang</h4>
                    <p className="text">
                      Founder
                    </p>
                    <a 
                      href="https://www.linkedin.com/in/jenchinwang" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="linkedin-link"
                      onClick={() => handleLinkedInClick('icon')}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor" 
                          viewBox="0 0 16 16"
                          className="linkedin-icon">
                          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </section>
            </div>

            <div className="footer">
                <div className="footer-content">

                    <span className="footer-text">Contact us at</span>
                    <a 
                        href="mailto:james.wang@careerduck.io" 
                        className="contact-link"
                        >
                        contact@careerduck.io
                    </a>
                </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};