// components/JobResults/JobSummary.tsx
import React from 'react';
import { JobData } from '../../types/types';
import './JobResults.css';

interface JobSummaryProps {
  jobData: JobData | null;
}

export const JobSummary: React.FC<JobSummaryProps> = ({ jobData }) => {
  return (
    <div className="job-summary">
      <h2>📝 Job Summary</h2>
      {jobData ? (
        <div>
          <h3>Title:</h3> 
          <p>{jobData.parsed_summary.title} at {jobData.parsed_summary.company_name}</p>
          <h3>Keywords:</h3>
          <p>{jobData.parsed_summary.keywords.join(', ')}</p>
          <h3>Company Overview:</h3> 
          <p>{jobData.parsed_summary.company_overview}</p>
          <h3>Location:</h3>
          <p>{jobData.parsed_summary.location}</p>
          <h3>Key Responsibilities:</h3>
          <ul>
            {jobData.parsed_summary.responsibilities.map((resp, index) => (
              <li key={index}>{resp}</li>
            ))}
          </ul>
          <h3>Required Qualifications:</h3>
          <ul>
            {jobData.parsed_summary.qualifications.map((qual, index) => (
              <li key={index}>{qual}</li>
            ))}
          </ul>
          <h3>Benefits:</h3>
          <ul>
            {jobData.parsed_summary.benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
        </div>
      ) : (
        <p className="placeholder-text">
          A summary including Title, Keywords, Role & Responsibilities will appear here.
        </p>
      )}  
    </div>
  );
};