// components/JobResults/ResumeBullets.tsx
import React from 'react';
import { JobData } from '../../types/types';
import './JobResults.css';

interface ResumeBulletsProps {
  jobData: JobData | null;
}

export const ResumeBullets: React.FC<ResumeBulletsProps> = ({ jobData }) => {
  return (
    <div className="resume-bullets">
      <h2>💡 Bullet Point Ideas</h2>
      {jobData && jobData.parsed_bullets ? (
        <div>
          {Object.entries(jobData.parsed_bullets).map(([responsibility, bullets], index) => (
            <div key={index}>
              <h3>{responsibility}</h3>
              <ul>
                {bullets.map((bullet, bulletIndex) => (
                  <li key={bulletIndex}>{bullet}</li>
                ))}
              </ul>
            </div>      
          ))}              
        </div>
      ) : (
        <p className="placeholder-text">Career Duck will suggest relevant, job-specific bullet points!</p>
      )}
    </div>
  );
};