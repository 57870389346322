// services/jobService.ts
import axios from 'axios';
import { SubmissionData, JobData } from '../types/types';
import { recordSearch } from '../utils/firebase';

export const submitJobData = async (
  submissionData: SubmissionData,
  apiUrl: string,
  onSuccess: (data: JobData) => void,
  onError: (error: string) => void
) => {
  try {
    const response = await axios.post(`${apiUrl}/summarize`, submissionData);
    const jobData = response.data;
    
    await recordSearch({
      url: submissionData.url,
      job_title: jobData.parsed_summary.title,
      company: jobData.parsed_summary.company_name,
      response: {
        job_content: jobData.job_content,
        parsed_summary: jobData.parsed_summary,
        parsed_bullets: jobData.parsed_bullets
      },
      input_method: submissionData.method
    });

    onSuccess(jobData);
    
  } catch (error: any) {
    await recordSearch({
      url: submissionData.url,
      job_title: null,
      company: null,
      response: null,
      error: error.message,
      input_method: submissionData.method
    });

    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (['job_not_found', 'job_expired', 'job_removed', 'invalid_url'].includes(error.response.data.error)) {
          onError('This job posting cannot be found or may not be live.');
        } else if (error.response.data.error.includes('content_error')) {
          onError('The content of the job posting has insufficient information.');
        } else {
          onError(`${error.response.data.message || 'Unknown error'}`);
        }
      } else if (error.request) {
        if (error.code === 'ECONNABORTED') {
          onError('The request timed out. The server took too long to respond.');
        } else {
          onError('No response received from the server. Please check your internet connection and try again.');
        }
      } else {
        onError(`An error occurred: ${error.message}`);
      }
    } else {
      onError('An unexpected error occurred. Please try again.');
    }
    throw error;
  }
};